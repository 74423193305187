.failurePageWrapper {
  padding-top: 103px;
  @media screen and (min-width: 790px) {
    padding-top: 250px;
  }
}
.content {
  font-size: 15px;
  line-height: 24px
}
