.headerWraper {
  @media only screen and (min-width: 790px) {
    position: fixed;
  }
  background-color: white;
  width: 100%;
  padding-top: 24px;
  text-align: center;
  .logo {
    width: 80px;
  }
}
