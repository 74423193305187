.loadingWrapper {
  .spinnerPosition {
    padding-top: 100px;
    @media screen and (min-width: 790px) {
      padding-top: 200px;
    }
    text-align: center;
    width: 100%;
  }
  p {
    font-size: 19px;
  }
}
