.paymentWraper {
  padding-top: 56px;
  @media screen and (min-width: 790px) {
    padding-top: 103px;
  }
  p {
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
  }
  .iframe {
    background-color: #F7F8FA;
  }
  .iframeLoading {
    margin-top: 12rem;
    position: absolute;
    margin-left: 10rem;
    @media only screen and (min-width: 790px) {
      margin-left: 14rem;
    }
  }
  .overlayLoading {
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    svg{
      margin: 0 auto;
      top:50%;
      left: 0;
      right: 0;
      position: absolute;
      width: 100%;
    }
  }
}

.loadingWraper {
  padding-top: 50px;
}
