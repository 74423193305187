.thankyouWrapper {
  padding-top: 56px;
  @media screen and (min-width: 790px) {
    padding-top: 103px;
  }
  .heading {
    font-size: 19px;
    margin: 0;
  }
  .content {
    font-size: 15px;
    line-height: 24px
  }
  .buttonPosition {
    text-align: right;
  }
}
