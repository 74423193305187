.payNowWrapper {
  padding-top: 80px;
  @media screen and (min-width: 790px) {
    padding-top: 120px;
  }
}
.textBox {
  display: flex;
  align-items: center;
  :global {
    .ixOVjl {
      width: 100% !important;
      @media screen and (min-width: 790px) {
        width: 72% !important;
      }
    }
  }
}
.radioGroup {
  display: flex;
  align-items: center;
}
.radioGroup {
  margin-top: 20px;
}
.iframe {
  background-color: #F7F8FA;
  margin-top: 30px;
}
.confirmButton {
  padding-top: 23px;
  text-align: center;
}
.divOutline {
  border-style: solid;
  border-width: 2px;
  border-color: darkgrey;
  margin: 5px;
  padding: 0 10px 10px;
}
.spacing {
  margin-bottom: 20px;
}
.checkBoxText {
  color: #403d3d;
}
:global {
  .febWOJ {
    flex-direction: row !important;
    justify-content: center !important;
  }
  .febWOJ .sc-dnqmqq ~ .sc-dnqmqq {
    margin-top: 0 !important;
    margin-left: 50px !important;
  }
  .eixtco {
    color: black !important;
  }
  .eRflzu {
    color: black;
    border-radius: 100%;
    border: 2px solid black;
  }
}
